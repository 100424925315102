import {ClubBookingSlot} from "../../../ApiDomain";
import {SlotCalculationErrorResponse} from "./domain";


export const dateWithoutTimezone = (date?: Date): string => {
    if (!date) {
        date = new Date()
    }
    return new Date(date.valueOf() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, -1);
}

export const calculateSlotsWithNewCount = (existingSlots: ClubBookingSlot[],
                                           newCount: number,
                                           bookingId?: string): SlotCalculationErrorResponse => {
    if (existingSlots.length === newCount) {
        return {
            slots: existingSlots
        }
    }

    let finalSlots: ClubBookingSlot[] = calculateNonEmptySlots(existingSlots)

    if (finalSlots.length > newCount) {
        return {
            slots: [],
            error: "TOO_MANY_NON_EMPTY_SLOTS"
        }
    }

    for (let i = finalSlots.length; i < newCount; i++) {
        finalSlots.push({
            bookingId: bookingId ? bookingId : "",
            status: existingSlots.filter(slot => slot.status === 'PUBLIC').length >= 1 ? 'PUBLIC' : 'OPEN',
            isMemberRequest: false
        })
    }

    return {
        slots: finalSlots
    }
}

export const calculateNonEmptySlots = (slots: ClubBookingSlot[]): ClubBookingSlot[] => {
    return slots.filter(slot => slot.caddieId || slot.slotTypeId || slot.player)
}

export const calculateNumberOfTeeTimesInPool = (startTeeTime?: string, endTeeTime?: string, teeTimes?: string[]): number => {
    if (!startTeeTime || !teeTimes) {
        return 1;
    }

    let startSplit = startTeeTime.split(":")
    let start = new Date()
    start.setHours(Number(startSplit[0]), Number(startSplit[1]))

    let end = new Date(start);
    if (endTeeTime) {
        let endSplit = endTeeTime.split(":")
        end.setHours(Number(endSplit[0]), Number(endSplit[1]))
    }

    let teeTimesInPool = teeTimes
        .map(tt => {
            let t = new Date();
            let split = tt.split(":")
            t.setHours(Number(split[0]), Number(split[1]))
            return t
        })
        .filter(time => time >= start && time <= end);

    return Math.max(teeTimesInPool.length, 1);
}
import React, {useState} from "react";
import ClubSelectorDropdown from "../components/dropdown/clubselector/ClubSelectorDropdown";
import './Settings.scss';
import SlotTypesSettingsPage from "../parts/settings/slottype/SlotTypesSettingsPage";
import ClubSettingsPage from "../parts/settings/club/ClubSettingsPage";
import TimesheetSettingsPage from "../parts/settings/timesheet/TimesheetSettingsPage";
import {useSelector} from "react-redux";
import {RootState} from "../services/store/store";
import CoursesSettingsPage from "../parts/settings/courses/CoursesSettingsPage";

enum Page {
    SLOT_TYPE,
    CLUB,
    COURSES,
    TIMESHEET
}

interface Props {
}

export const Settings = ({}: Props) => {

    const { club, hasMultipleCourses } = useSelector((state: RootState) => state.clubs.selectedClub);

    const [selectedPage, setSelectedPage] = useState<Page>(Page.CLUB)

    return (
        <div>
            <div className="settings">
                <div className="navigation-sidebar">
                    <div className="settings-title-text">
                        SETTINGS
                    </div>
                    <div style={{marginTop: 20, marginLeft: 20}}>
                        <ClubSelectorDropdown classNames="settings-page-club-selector"/>
                    </div>
                    <div className={selectedPage === Page.CLUB ? 'settings-page-navigation-item-selected' : 'settings-page-navigation-item'}
                         onClick={() => setSelectedPage(Page.CLUB)}>
                        Club
                    </div>
                    <div className={selectedPage === Page.SLOT_TYPE ? 'settings-page-navigation-item-selected' : 'settings-page-navigation-item'}
                         onClick={() => setSelectedPage(Page.SLOT_TYPE)}>
                        Slot Types
                    </div>
                    {hasMultipleCourses && <div className={selectedPage === Page.COURSES ? 'settings-page-navigation-item-selected' : 'settings-page-navigation-item'}
                         onClick={() => setSelectedPage(Page.COURSES)}>
                        Courses
                    </div>}
                    {club && club.hasTimesheet && <div className={selectedPage === Page.TIMESHEET ? 'settings-page-navigation-item-selected' : 'settings-page-navigation-item'}
                         onClick={() => setSelectedPage(Page.TIMESHEET)}>
                        Timesheet
                    </div>}
                </div>

                <div className="slot-type-container">
                    {selectedPage === Page.CLUB && <ClubSettingsPage />}
                    {selectedPage === Page.SLOT_TYPE && <SlotTypesSettingsPage />}
                    {selectedPage === Page.COURSES && <CoursesSettingsPage />}
                    {selectedPage === Page.TIMESHEET && <TimesheetSettingsPage />}
                </div>
            </div>

        </div>
    )
}
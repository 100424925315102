import React, {useState} from 'react';
import moment from "moment";
import {BookingSlot, BookingSlotPlayer, ClubBooking} from "../../../services/ApiDomain";
import {useApi} from "../../../services/useApi";
import {logErrorFromApi, uuidv4} from "../../../services/Utils";
import {toast} from "react-toastify";
import CaddieBadge from "./components/CaddieBadge";
import EmptySlot from "./components/EmptyCaddieSlot";
import {handicaddieSelector} from "../../../services/store/asyncThunk";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../services/store/store";
import {loadTimesheet, reloadScheduler} from "../../../services/store/reducers/scheduler/asyncActions";
import {Member} from "../../../services/player/MemberService";

type TeeTimeCaddiesTableColumnProps = {
    teeTime: string,
    booking?: ClubBooking,
    courseId?: string,
    teeTimesInPool?: number,
    refreshTimesheet: () => void,
}

const TeeTimeCaddiesTableColumn = ({teeTime, booking, courseId, teeTimesInPool, refreshTimesheet}: TeeTimeCaddiesTableColumnProps) => {

    const [loading, setLoading] = useState<boolean>(false)

    const [indexHovered, setIndexHovered] = useState<number>(-1)

    const {
        getAvailableCaddiesForBooking,
        updateBookingSlot,
        createBookingSlots,
        createBooking
    } = useApi();

    const { selectedDate } = handicaddieSelector(state => state.scheduler)
    const { club } = handicaddieSelector(state => state.clubs.selectedClub)

    const dispatch = useDispatch<AppDispatch>()

    const calculateEmptySlots = (slots: BookingSlot[]): number => {
        // if it's a caddie pool & caddies already a multiple of 4 & there's more slots remaining
        // then display a full row of empty slots
        if (booking && teeTimesInPool) {
            if (teeTimesInPool * 4 < slots.length + 4) {
                return slots.length % 4 === 0 ? 0 : 4 - slots.length % 4;
            }

            if (slots.length % 4 === 0) {
                return 4;
            }

            return 4 + (4 - slots.length % 4);
        }

        if (slots.length === 4) {
            return 0;
        }

        let emptySlots = slots.length % 4 > 0 ? (4 - slots.length) : 4 + slots.length % 4
        // this is here for when there was an error in slot creation and there's more than 4 slots
        // in a single group booking
        return Math.max(0, emptySlots)
    }

    let bookingSlots = booking && booking.bookingSlots ? booking.bookingSlots : []
    let emptySlots = calculateEmptySlots(bookingSlots)

    const drop = async (e: React.DragEvent, bookingSlot?: BookingSlot) => {
        e.preventDefault();
        const caddieId = e.dataTransfer.getData('caddieId');

        if (!caddieId || !booking?.bookingId || !bookingSlot?.id) {
            return;
        }

        try {
            setLoading(true)
            let bookingAvailabilities = await getAvailableCaddiesForBooking(booking.clubId, booking.teeTime)
            let isCaddieAvailable = bookingAvailabilities.resource
                .filter(avail => avail.caddieId === caddieId)
                .map(avail => avail.caddieId)
                .includes(caddieId);
            if (!isCaddieAvailable) {
                toast.error("Can't place caddie there, they're not available.")
                return
            }

            if (!bookingSlot?.id || !booking?.bookingId) {
                return
            }
            let slot: BookingSlot = {...bookingSlot}
            slot.caddieId = caddieId
            slot.isMemberRequest = false
            if (slot.status === 'PUBLIC') {
                slot.status = 'PENDING'
            }
            await updateBookingSlot(slot)
        } catch (e) {
            logErrorFromApi(e)
        } finally {
            dispatch(reloadScheduler());
            setLoading(false)
        }
    }

    const dragOver = (e: React.DragEvent) => {
        e.preventDefault();
    }

    const addSlot = async (newCaddies: number, bookingId?: string, ) => {
        if (!emptySlots && (newCaddies > 0 || !booking) || !club) {
            return;
        }

        let slots = Array(newCaddies).fill({
            bookingId: bookingId,
            status: "OPEN"
        })
        try {
            setLoading(true)
            if (!bookingId) {
                let createdBooking = await createBooking(
                    club.id,
                    `${moment(selectedDate).format('YYYY-MM-DD')}T${teeTime}`,
                    undefined,
                    undefined,
                    undefined,
                    courseId);
                bookingId = createdBooking.id
            }
            await createBookingSlots(bookingId, slots)
            dispatch(loadTimesheet())
        } finally {
            dispatch(loadTimesheet())
            setLoading(false)
        }
    }

    const buildBadges = (): JSX.Element[] => {
        let badges: JSX.Element[] = []
        let row: JSX.Element[] = []

        const pushRow = () => {
            badges.push(<div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: "0.5em", marginBottom: '0.5em'}}>
                {row}
            </div>)
            row = []
        }

        const checkRow = () => {
            if (row.length === 4) {
                pushRow()
            }
        }

        let players: BookingSlotPlayer[] = []
        bookingSlots.forEach(slot => {
            if (!slot.player) {
                return;
            }
            if (players.includes(slot.player)) {
                return;
            }

            players.push(slot.player)
        })

        {booking && bookingSlots.map((bookingSlot) => {
                checkRow()
                row.push(<CaddieBadge
                    key={`${booking.bookingId}-${bookingSlot.id}`}
                    clubId={booking.clubId}
                    bookingId={booking.bookingId}
                    onDragOver={dragOver}
                    onDrop={(e: React.DragEvent) => drop(e, bookingSlot)}
                    onStatusChange={refreshTimesheet}
                    bookingSlot={bookingSlot}
                    allPlayersInBooking={players}
                    onDelete={refreshTimesheet}
                    isFromBookingSidePanel={false}
                />)
            })
        }
        {[...Array(emptySlots)].map((_, idx) => {
            checkRow()
            row.push(<EmptySlot
                key={uuidv4()}
                loading={loading}
                onClick={() => addSlot(idx + 1, booking?.bookingId)}
                className={idx <= indexHovered ? "emptyCaddieSlot_hovered" : ""}
                onMouseEnter={() => setIndexHovered(idx)}
                onMouseLeave={() => setIndexHovered(-1)}
            />)
        })}

        pushRow()

        return badges
    }

    return (
        <>
            <div onMouseLeave={() => setIndexHovered(-1)}>
                {buildBadges()}
            </div>
        </>
    )
}

export default TeeTimeCaddiesTableColumn;
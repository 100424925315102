import React, {useEffect, useState} from 'react'
import {useCombobox} from 'downshift'
import {Course} from '../../../../../services/ApiDomain'
import {useDialog} from '../../../../../hooks/use-dialog';
import {AddCourseModal} from './AddCourseModal';
import {handicaddieSelector} from "../../../../../services/store/asyncThunk";

type Props = {
    teeTime?: string
    onSelectCourse: (id: string) => void,
    clickedTimesheetRowCourseId?: string,
    disable: boolean
}

export const CourseCreationAndSelection = ({teeTime, onSelectCourse, clickedTimesheetRowCourseId, disable}: Props) => {

    // const dialog = useDialog()
    const [filteredCourses, setFilteredCourses] = useState<Course[]>([]);

    const { club, courses } = handicaddieSelector(state => state.clubs.selectedClub)

    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
        selectedItem,
        selectItem,
        inputValue,
        setInputValue,
    } = useCombobox({
        items: filteredCourses,
        itemToString: (item) => item?.name || '',
        onSelectedItemChange(changes) {
            setInputValue("")
        },
    })

    useEffect(() => {
        selectItem(null)
        setFilteredCourses([])
    }, [teeTime]);

    useEffect(() => {
        setFilteredCourses(
            courses.filter(c => c.name.toLowerCase().includes(inputValue.toLowerCase()))
        )
    }, [inputValue])

    useEffect(() => {
        if (selectedItem) {
            onSelectCourse(selectedItem.id)
        }
    }, [selectedItem])

    // effect for setting and showing the course id if timesheet row clicked.
    useEffect(() => {
        const isCourseFound = courses.find(course => course.id === clickedTimesheetRowCourseId)
        if (isCourseFound) {
            selectItem(isCourseFound)
        }
    }, [clickedTimesheetRowCourseId, courses])

    return (
        <div style={{ marginBottom: '2em' }}>
            {/*<div className='d-flex justify-content-between align-items-center w-100 mb-2'>*/}
            {/*    <label className='m-0'*/}
            {/*            style={{*/}
            {/*                fontWeight: 'bolder',*/}
            {/*            }}*/}
            {/*            {...getLabelProps()}*/}
            {/*        >*/}
            {/*        Add a Course*/}
            {/*    </label>*/}
            {/*    {!disable && club && <button*/}
            {/*        className='btn btn-link p-0'*/}
            {/*        onClick={() => dialog.handleOpen({clubId: club.id})}>*/}
            {/*        Add Course</button>}*/}
            {/*</div>*/}
            <h6>Course</h6>

            <div className='d-flex align-items-start' style={{gap: "6px"}}>
                <div className='d-flex flex-column' style={{flex: "1 1 auto"}}>
                    <div style={{flex: "1 1 auto"}} className='position-relative'>
                        <input
                            style={{padding: '10px', width: "100%", height: "40px"}}

                            {...getInputProps()}
                            data-testid="combobox-input"
                            className='course-selection-box'
                            disabled={selectedItem !== null}
                        />
                        {selectedItem &&
                            <div className='course-li-element-positioned'
                                 style={{backgroundColor: selectedItem.colourCode}} {...getToggleButtonProps()}>
                                {selectedItem.name}
                            </div>
                        }
                    </div>

                    <ul
                        {...getMenuProps()}
                        style={{
                            listStyle: 'none',
                            width: '100%',
                            margin: '4px 0 0 0',
                            background: "white",
                            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.5)",
                            borderRadius: "0.5rem",
                            maxHeight: "400px",
                            overflowY: "scroll",
                            padding: '10px 10px 15px 10px',
                            display: isOpen && filteredCourses.length > 0 ? "block" : "none"
                        }}
                    >
                        {isOpen && filteredCourses.length > 0 && (
                            filteredCourses.map((item, index) => (
                                <li
                                    className='course-li-element'
                                    key={`${item.id}${index}`}
                                    {...getItemProps({
                                        item: item,
                                        index,
                                    })}
                                >
                                    <span
                                        style={{
                                            backgroundColor: highlightedIndex === index ? '#eee' : item.colourCode,
                                        }}
                                    >
                                        {item.name}
                                    </span>
                                </li>
                            ))
                        )}
                    </ul>
                </div>

                {!disable && <button
                    style={{padding: '0px 8px', fontSize: "22px"}}
                    aria-label="toggle menu"
                    data-testid="clear-button"
                    onClick={() => selectItem(null)}
                    className='btn btn-outline-ghost'
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="currentColor"
                        viewBox="0 0 16 16"
                    >
                        <path
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                    </svg>

                </button>}

            </div>
            {/*<AddCourseModal open={dialog.open} onClose={dialog.handleClose} itemData={dialog.data} onSelectCourse={selectItem} />*/}
        </div>
    )
}
